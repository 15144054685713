.list {
    text-align: left;
    max-width: 750px;
    margin: auto;
}

.submit-form {
    max-width: 300px;
    margin: auto;
}

.edit-form {
    max-width: 300px;
    margin: auto;
}